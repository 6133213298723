import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';

const PageLayout = ({ 
  title, 
  description, 
  children, 
  className = "page-layout",
  showHero = true 
}) => {
  const { theme, setTheme } = useTheme();

  return (
    <div className={`${className} ${theme}`}>
      {showHero && (
        <section className="hero-section">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className="hero-container"
          >
            <h1 className="hero-title">{title}</h1>
            <p className="hero-description">{description}</p>
          </motion.div>
        </section>
      )}

      {/* Enhanced Background Elements */}
      <div className="fixed inset-0 overflow-hidden pointer-events-none -z-10">
        <motion.div 
          className="bg-gradient-orb-1"
          animate={{ 
            scale: [1, 1.1, 1],
            opacity: [0.5, 0.3, 0.5] 
          }}
          transition={{ 
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        <motion.div 
          className="bg-gradient-orb-2"
          animate={{ 
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3] 
          }}
          transition={{ 
            duration: 8,
            repeat: Infinity,
            ease: "easeInOut",
            delay: 1
          }}
        />
      </div>

      {children}
    </div>
  );
};

export default PageLayout;