import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useTheme } from '../../contexts/ThemeContext';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Service area boundary configuration
const serviceAreaBoundary = {
  type: "Feature",
  properties: {},
  geometry: {
    type: "Polygon",
    coordinates: [[
      [-73.5, 42.0],  // Far Western MA (Pittsfield)
      [-72.9, 41.8],  // CT Border
      [-71.8, 41.6],  // Southern RI
      [-71.2, 41.3],  // Southern MA
      [-70.8, 41.2],  // South of Cape
      [-69.8, 41.2],  // South of Nantucket
      [-69.8, 41.5],  // East of Nantucket
      [-70.0, 41.5],  // Tip of Cape Cod
      [-69.8, 42.0],  // Ocean east of Cape
      [-69.9, 42.5],  // Ocean northeast of Cape
      [-70.0, 43.5],  // Maine Coast
      [-70.2, 43.8],  // Portland Area
      [-71.2, 43.9],  // Laconia, NH Area
      [-71.8, 43.7],  // NH Interior
      [-72.5, 43.4],  // VT Border
      [-73.3, 42.8],  // NY Border
      [-73.5, 42.0]   // Back to start
    ]]
  }
};

const geoJSONStyle = {
  fillColor: '#2563eb',
  fillOpacity: 0.2,
  color: '#1d4ed8',
  weight: 2
};

const Map = ({ userLocation, areas }) => {
  const { theme, setTheme } = useTheme();
  const MAP_CENTER = userLocation || [43.0, -70.8];
  const ZOOM_LEVEL = 7;

  return (
    <MapContainer
      center={MAP_CENTER}
      zoom={ZOOM_LEVEL}
      className="h-full w-full"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />
      <GeoJSON 
        data={serviceAreaBoundary}
        style={geoJSONStyle}
      />
      {userLocation && (
        <Marker position={userLocation}>
          <Popup>Your approximate location</Popup>
        </Marker>
      )}
    </MapContainer>
  );
};

export default Map;