import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import AreaCard from './AreaCard';
import { useTheme } from '../../contexts/ThemeContext';

const AreasGrid = React.memo(({ areas }) => {
  const { theme, setTheme } = useTheme();

  return (
    <motion.div 
      className="areas-grid"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <AnimatePresence mode="wait" initial={true}>
        {areas.map((area, index) => (
          <AreaCard
            key={`${area.name}-${area.state}`}
            area={area}
            index={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{
              duration: 0.3,
              delay: index * 0.05,
              ease: "easeOut"
            }}
          />
        ))}
      </AnimatePresence>
    </motion.div>
  );
});

export default AreasGrid;