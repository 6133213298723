import React, { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';

const ContactForm = ({ onSubmit }) => {
  const { theme, setTheme } = useTheme();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    try {
      await onSubmit(formData);
      setStatus({
        type: 'success',
        message: 'Thank you for reaching out! We will get back to you soon.'
      });
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      setStatus({
        type: 'error',
        message: 'There was an error sending your message. Please try again or call us directly.'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`form-container ${theme}`}>
      <h3 className="form-title">Send Us a Message</h3>
      
      {status.message && (
        <div className={`form-message ${status.type === 'success' ? 'success' : 'error'}`}>
          {status.message}
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="form-group">
        <div>
          <label htmlFor="name" className="form-label">Name</label>
          <input 
            id="name"
            name="name"
            type="text" 
            value={formData.name}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Your name"
            required
            disabled={isSubmitting}
          />
        </div>

        <div>
          <label htmlFor="email" className="form-label">Email</label>
          <input 
            id="email"
            name="email"
            type="email" 
            value={formData.email}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Your email"
            required
            disabled={isSubmitting}
          />
        </div>

        <div>
          <label htmlFor="message" className="form-label">Message</label>
          <textarea 
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            className="form-textarea"
            placeholder="How can we help you?"
            required
            disabled={isSubmitting}
          />
        </div>

        <button 
          type="submit"
          className="form-submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;