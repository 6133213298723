import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';

const AreaCard = motion(React.memo(({ area, index }) => {
  const { theme, setTheme } = useTheme();

  return (
    <div className={`areas-card animate-fadeInUp opacity-0 delay-${index * 50}`}>
      <h3 className="text-lg text-text-primary">{area.name}</h3>
      <p className="text-sm text-text-secondary">{area.state}</p>
    </div>
  );
}));

export default AreaCard;