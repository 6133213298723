import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext({
  theme: 0,
  setTheme: () => {}
});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(0);

  // Load theme from localStorage or use default (0)
  useEffect(() => {
    const savedTheme = parseInt(localStorage.getItem('theme')) || 0;
    setTheme(savedTheme);
  }, []);

  // Save theme changes to localStorage
  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.documentElement.classList.remove('theme-0', 'theme-1', 'theme-2', 'theme-3', 'theme-4');
    document.documentElement.classList.add(`theme-${theme}`);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);