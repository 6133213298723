import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import SEO from './shared/SEO';
import PageLayout from './layout/PageLayout';
import { useTheme } from '../contexts/ThemeContext';

const AboutPage = () => {
  const { theme, setTheme } = useTheme();
  const aboutSchema = {
    "@context": "https://schema.org",
    "@type": "AboutPage",
    "mainEntity": {
      "@type": "Organization",
      "name": "Tim's Tech",
      "foundingDate": "2012",
      "description": "Professional IT services provider serving Massachusetts since 2012",
      "awards": [
        "CCNA Certified",
        "Apple Certified",
        "Microsoft Certified"
      ]
    }
  };

  const commitments = [
    "24/7 availability",
    "Skilled, certified technicians",
    "Privacy first approach",
    "Custom solutions built for your needs",
    "Transparent communication and pricing",
    "A lasting partnership focused on your success"
  ];

  const [appleClickCount, setAppleClickCount] = useState(0);
  const [windowsClickCount, setWindowsClickCount] = useState(0);
  const [linuxClickCount, setLinuxClickCount] = useState(0);
  const [appleImage, setAppleImage] = useState('/images/cert/apple.png');
  const [windowsImage, setWindowsImage] = useState('/images/cert/windows.png');
  const [linuxImage, setLinuxImage] = useState('/images/cert/linux.png');
  const [ccnaImage, setCcnaImage] = useState('/images/cert/ccna-certified.png');
  const [expImage, setExpImage] = useState('/images/cert/35.png');

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    
    const updateImages = (isDark) => {
      setCcnaImage(isDark ? '/images/cert/ccna-certified-dm.png' : '/images/cert/ccna-certified.png');
      setExpImage(isDark ? '/images/cert/35-dm.png' : '/images/cert/35.png');
    };

    // Initial check
    updateImages(darkModeMediaQuery.matches);

    // Listen for changes
    const listener = (e) => updateImages(e.matches);
    darkModeMediaQuery.addEventListener('change', listener);

    return () => darkModeMediaQuery.removeEventListener('change', listener);
  }, []);

  const handleAppleClick = () => {
    setAppleClickCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 3) {
        setAppleImage('/images/cert/retro_apple.png');
      } else if (newCount === 8) {
        setAppleImage('/images/cert/very_retro_apple.png');
      }
      return newCount;
    });
  };

  const handleWindowsClick = () => {
    setWindowsClickCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 3) {
        setWindowsImage('/images/cert/retro_windows.png');
      } else if (newCount === 8) {
        setWindowsImage('/images/cert/very_retro_windows.png');
      }
      return newCount;
    });
  };

  const handleLinuxClick = () => {
    setLinuxClickCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 5) {
        setLinuxImage('/images/cert/gnu_logo.png');
      }
      return newCount;
    });
  };

  return (
    <>
      <SEO
        title="About Tim's Tech | Massachusetts IT Services Since 2012"
        description="Family-owned IT services provider in Massachusetts since 2012. CCNA certified experts delivering quality tech support and custom solutions."
        pathname="/about"
      >
        <script type="application/ld+json">
          {JSON.stringify(aboutSchema)}
        </script>
      </SEO>

      <PageLayout
        title="About Us"
        description="Your family-owned IT services provider since 2012"
      >
        {/* Main Content */}
        <section className="about-main-content">
          <div className="about-main-container">
            <motion.div 
              className="max-w-4xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <div className="about-main-text">
                <p>
                  Since 2012, Tim's Tech has been helping businesses and individuals across New England with dependable tech support and custom solutions.
                </p>
                
                <p>
                  As a family-owned business, we believe in combining expertise with a personal approach. Our team offers a range of services, from full-stack development and hardware support to network setup and cybersecurity. We stay up-to-date with the latest technology trends, including advancements in AI, networking, and digital privacy, so we can deliver solutions that meet today's needs.
                </p>
                
                <p>
                  Whether you need to strengthen cybersecurity, set up a network, or develop a custom solution, we're here to help – on-site or remotely.
                </p>
              </div>

              {/* Certifications */}
              <motion.div 
                className="about-certifications"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                <img 
                  src={ccnaImage}
                  alt="CCNA Certification" 
                  className="about-certification-image"
                />
                <img 
                  src={expImage}
                  alt="35 Years Combined Experience" 
                  className="about-certification-image"
                />
                <img 
                  src={appleImage} 
                  alt="Apple" 
                  className="about-certification-image" 
                  onClick={handleAppleClick}
                />
                <img 
                  src={windowsImage} 
                  alt="Windows" 
                  className="about-certification-image" 
                  onClick={handleWindowsClick}
                />
                <img 
                  src={linuxImage} 
                  alt="Linux" 
                  className="about-certification-image" 
                  onClick={handleLinuxClick}
                />
              </motion.div>

              {/* Commitments Section */}
              <motion.div 
                className="about-commitments-section"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.6 }}
              >
                <h2 className="about-commitments-title">
                  Our Commitment
                </h2>
                <div className="about-commitments-grid">
                  {commitments.map((commitment, index) => (
                    <div key={index} className="about-commitment-item">
                      <div className="about-commitment-icon" />
                      <span className="about-commitment-text">{commitment}</span>
                    </div>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          </div>
        </section>
      </PageLayout>
    </>
  );
};

export default AboutPage;