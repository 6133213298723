import React, { useState, useEffect } from 'react';
import { useTheme } from './contexts/ThemeContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/layout/Header';
import HomePage from './components/HomePage';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicesPage';
import AreasPage from './components/AreasPage';
import ContactPage from './components/ContactPage';

const App = () => {
  const [keyPressCount, setKeyPressCount] = useState(0);
  const [lastKey, setLastKey] = useState(null);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // We only care about digits '0' through '4'
      if (['0', '1', '2', '3', '4'].includes(e.key)) {
        if (e.key === lastKey) {
          setKeyPressCount(count => count + 1);
        } else {
          setLastKey(e.key);
          setKeyPressCount(1);
        }
      } else {
        // If a different key is pressed, reset
        setLastKey(null);
        setKeyPressCount(0);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [lastKey]);

  const { theme, setTheme } = useTheme();

  useEffect(() => {
    if (keyPressCount === 5) {
      let newTheme = 0;
      switch (lastKey) {
        case '0':
          newTheme = 0;
          break;
        case '1':
          newTheme = 1;
          break;
        case '2':
          newTheme = 2;
          break;
        case '3':
          newTheme = 3;
          break;
        case '4':
          newTheme = 4;
          break;
        default:
          break;
      }
      setTheme(newTheme);
      // Reset count so it won't trigger again until next sequence
      setKeyPressCount(0);
    }
  }, [keyPressCount, lastKey, setTheme]);

  return (
    <HelmetProvider>
      <Router>
        <div className="min-h-screen bg-bg-primary">
          <Header />
          
          {/* Main Content */}
          <main className="py-8">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/areas" element={<AreasPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </main>

          {/* Footer */}
          <footer className="bg-bg-secondary shadow-md mt-auto">
            <div className="container mx-auto px-4 py-6">
              <div className="text-center text-text-secondary">
                <p>&copy; {new Date().getFullYear()} Tim's Tech. All rights reserved.</p>
              </div>
            </div>
          </footer>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;