import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Phone, Clock, MapPin, Menu, X } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';


const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const { theme, setTheme } = useTheme();
  const isActiveRoute = (path) => location.pathname === path;

  return (
    <header className="header">
      {/* Accessibility skip link */}
      <a href="#main-content" className="skip-link">
        Skip to main content
      </a>

      {/* Top Info Banner */}
      <div className="top-info-banner">
        <div className="top-info-container">
          <div className="top-info-flex">
            <div className="flex items-center space-x-8">
              <div className="top-info-item">
                <Clock className="top-info-icon" aria-hidden="true" />
                <span className="top-info-text">Open 24/7/365</span>
              </div>
              <div className="top-info-item">
                <MapPin className="top-info-icon" aria-hidden="true" />
                <span className="top-info-text">Lowell, MA</span>
              </div>
            </div>
            <a 
              href="tel:+17813668800" 
              className="call-now-button"
            >
              <Phone className="call-now-icon" aria-hidden="true" />
              <span className="call-now-text">
                Call Now: (781) 366-8800
              </span>
            </a>
          </div>
        </div>
      </div>

      {/* Main Navigation */}
      <nav className="main-nav">
        <div className="main-nav-container">
          <div className="main-nav-flex">
            {/* Logo */}
            <Link to="/" className="logo-container">
              <div className="logo">
                <span className="logo-text">T</span>
              </div>
              <div>
                <h1 className="logo-title">Tim's Tech</h1>
                <p className="logo-subtitle">Professional IT Services</p>
              </div>
            </Link>

            {/* Desktop Navigation */}
            <div className="desktop-nav">
              {[
                { name: 'Home', path: '/' },
                { name: 'Services', path: '/services' },
                { name: 'About', path: '/about' },
                { name: 'Areas', path: '/areas' },
                { name: 'Contact', path: '/contact' }
              ].map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`nav-link ${isActiveRoute(item.path) ? 'active' : 'inactive'}`}
                >
                  {item.name}
                  {isActiveRoute(item.path) && (
                    <div className="nav-link-active-indicator" />
                  )}
                </Link>
              ))}
            </div>

            {/* Mobile Menu Button */}
            <button
              className="mobile-menu-button"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              aria-expanded={isMobileMenuOpen}
              aria-controls="mobile-menu"
            >
              {isMobileMenuOpen ? (
                <X className="w-6 h-6 text-gray-600" />
              ) : (
                <Menu className="w-6 h-6 text-gray-600" />
              )}
            </button>
          </div>

          {/* Mobile Navigation */}
          <div 
            id="mobile-menu"
            className={`mobile-menu ${isMobileMenuOpen ? 'block' : 'hidden'}`}
          >
            <div className="py-2 space-y-1">
              {[
                { name: 'Home', path: '/' },
                { name: 'Services', path: '/services' },
                { name: 'About', path: '/about' },
                { name: 'Areas', path: '/areas' },
                { name: 'Contact', path: '/contact' }
              ].map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`mobile-menu-item ${isActiveRoute(item.path) ? 'active' : 'inactive'}`}
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;