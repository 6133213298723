import React, { useRef, useEffect, useState } from 'react';
import { Shield, Zap, Network, ArrowRight } from 'lucide-react';
import { motion, useScroll, AnimatePresence } from 'framer-motion';
import PageLayout from './layout/PageLayout';
import businessTypes from '../data/businesstypes.jsx';
import CTASection from './shared/CTASection';
import ServiceSection from './shared/ServicesSection';
import { useTheme } from '../contexts/ThemeContext';

const HomePage = () => {
  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { theme, setTheme } = useTheme();
  
  useScroll({
    target: containerRef,
    offset: ["start start", "end end"]
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex(current => (current + 1) % businessTypes.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  return (
    <PageLayout showHero={false}>
      <div ref={containerRef} className="bg-bg-primary">
        <section className="relative min-h-[80vh] flex items-center pt-12">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto text-center">
              <motion.div 
                className="flex flex-col items-center"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.8 }}
              >
                <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold leading-tight">
                  Elevating
                </h1>
                
                {/* Business Type Animation Container */}
                <div className="my-2 sm:my-4 relative w-full">
                  <AnimatePresence initial={false} mode="wait">
                    <motion.div
                      key={currentIndex}
                      className="w-full"
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.7, delay: 0.1 }}
                    >
                      <h2 className="text-size-dynamic font-semibold text-gradient leading-tight py-2 mb-0 break-words">
                        {businessTypes[currentIndex]}
                      </h2>
                    </motion.div>
                  </AnimatePresence>
                </div>

                <h1 className="text-4xl sm:text-5xl md:text-6xl font-semibold leading-tight mt-0 sm:mt-2 md:mt-4">
                  Through Technology
                </h1>

                <motion.p
                  className="text-xl text-text-secondary max-w-2xl mt-8 leading-relaxed"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.3 }}
                >
                  Enterprise-grade IT solutions tailored for New England businesses.
                </motion.p>

                <motion.div
                  className="flex flex-col sm:flex-row gap-6 items-center mt-12"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: 0.5 }}
                >
                  <motion.a
                    href="/contact"
                    className="button-link-small"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Start a Project
                    <ArrowRight className="ml-2 w-5 h-5" />
                  </motion.a>
                  <motion.a
                    href="/services"
                    className="link-secondary"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    Learn more about our services
                    <ArrowRight className="ml-2 w-5 h-5" />
                  </motion.a>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Services Section */}
        <ServiceSection 
          services={[
            {
              icon: Shield,
              title: "Security & Privacy",
              description: "Enterprise-grade protection",
              gradient: "from-purple-600 to-blue-600"
            },
            {
              icon: Zap,
              title: "Business Automation",
              description: "Streamlined workflows",
              gradient: "from-blue-600 to-cyan-500"
            },
            {
              icon: Network,
              title: "Network Solutions", 
              description: "Optimized infrastructure",
              gradient: "from-cyan-500 to-green-500"
            }
          ]}
          variant="primary"
          className="py-24"
        />

        {/* CTA Section */}
        <CTASection
          title="Ready to transform your business?"
          description="Contact us today to discuss your technology needs."
          primaryButtonText="Get Started"
          primaryButtonLink="/contact"
        />
      </div>
    </PageLayout>
  );
};

export default HomePage;