import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { useTheme } from '../../contexts/ThemeContext';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.3
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut"
    }
  }
};

const ServiceSection = ({ 
  services,
  variant = 'primary',
  className = '',
  columns = variant === 'secondary' ? 4 : 3
}) => {
  const { theme, setTheme } = useTheme();
  const isPrimary = variant === 'primary';
  
  const getGridClass = (cols) => {
    switch(cols) {
      case 2: return 'md:grid-cols-2';
      case 3: return 'md:grid-cols-3';
      case 4: return 'md:grid-cols-4';
      default: return 'md:grid-cols-3';
    }
  };
  
  return (
    <section className={`py-16 ${variant === 'secondary' ? 'bg-service-secondary-bg' : 'service-section'} ${className}`}>
      <div className="container mx-auto px-4">
        <motion.div 
          className={`grid grid-cols-1 ${getGridClass(columns)} gap-6 max-w-6xl mx-auto`}
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          {services.map((service, index) => (
            isPrimary ? (
              <motion.div
                key={index}
                className="service-card relative p-6"
                variants={itemVariants}
                whileHover={{ y: -5, transition: { duration: 0.3 } }}
              >
                {service.highlight && (
                  <div className="absolute top-2 right-2 px-3 py-1 rounded-full text-sm bg-service-highlight-bg text-service-highlight-text border border-service-highlight-ring shadow-sm">
                    {service.highlight}
                  </div>
                )}
                <div className={`service-icon bg-gradient-to-r ${service.gradient} mt-6`}>
                  <service.icon className="w-full h-full" />
                </div>
                <h3 className="service-heading">
                  {service.title}
                </h3>
                <p className="service-description">
                  {service.description}
                </p>
                {service.features && (
                  <ul className="space-y-3 mb-6">
                    {service.features.map((feature, idx) => (
                      <li key={idx} className="service-feature">
                        <div className="service-feature-dot" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                )}
                <a href={`/services#${service.title.toLowerCase()}`} 
                   className="link-primary">
                  Learn more
                  <ArrowRight className="ml-1 w-4 h-4 transition-transform group-hover:translate-x-1" />
                </a>
              </motion.div>
            ) : (
              <div
                key={index}
                className={`service-secondary secondary-service-item animate-fadeInUp delay-${(index + 1) * 200}`}
              >
                <div className={`service-secondary-icon bg-gradient-to-r ${service.gradient}`}>
                  <service.icon className="w-full h-full" />
                </div>
                <h4 className="text-lg font-semibold mb-2 text-text-primary">
                  {service.title}
                </h4>
                <p className="text-text-secondary text-sm">
                  {service.description}
                </p>
              </div>
            )
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default ServiceSection;