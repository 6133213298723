import React from 'react';
import { Shield, Zap, Network, Home, Monitor, Laptop, BookOpen } from 'lucide-react';
import SEO from './shared/SEO';
import PageLayout from './layout/PageLayout';
import CTASection from './shared/CTASection';
import ServiceSection from './shared/ServicesSection';
import { useTheme } from '../contexts/ThemeContext';

const ServicesPage = () => {
  const { theme, setTheme } = useTheme();
  const services = {
    primary: [
      {
        icon: Shield,
        title: "Cybersecurity & Privacy",
        description: "Enterprise-grade security solutions made accessible to everyone",
        features: [
          "Privacy-focused solutions",
          "Small business security consulting",
          "Secure network design",
        ],
        highlight: "Popular for small businesses!",
        gradient: "from-purple-600 to-blue-600"
      },
      {
        icon: Zap,
        title: "Smart Automation",
        description: "Streamline your business operations with custom automation solutions",
        features: [
          "AI-powered workflow automation",
          "Virtual assistant integration",
          "Custom business automation tools"
        ],
        highlight: "Save hours every week!",
        gradient: "from-blue-600 to-cyan-500"
      },
      {
        icon: Network,
        title: "Network Solutions",
        description: "Optimize your network for superior performance and security",
        features: [
          "Advanced firewall setup",
          "Wi-Fi optimization",
          "Network performance tuning"
        ],
        highlight: "Fix those dead spots!",
        gradient: "from-cyan-500 to-green-500"
      }
    ],
    secondary: [
      {
        icon: Home,
        title: "Smart Home Technology",
        description: "Transform your home with integrated smart technology solutions",
        gradient: "from-purple-500 to-pink-500"
      },
      {
        icon: Monitor,
        title: "System Optimization",
        description: "Maximize your computer's performance and efficiency",
        gradient: "from-blue-500 to-indigo-500"
      },
      {
        icon: Laptop,
        title: "Custom Solutions",
        description: "Tailored technology solutions for your specific needs",
        gradient: "from-teal-500 to-emerald-500"
      },
      {
        icon: BookOpen,
        title: "Technology Education",
        description: "Personal guidance for all skill levels",
        gradient: "from-orange-500 to-amber-500"
      }
    ]
  };

  const servicesSchema = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": services.primary.map((service, index) => ({
      "@type": "Service",
      "position": index + 1,
      "name": service.title,
      "description": service.description,
      "provider": {
        "@type": "LocalBusiness",
        "name": "Tim's Tech"
      },
      "areaServed": "Massachusetts"
    }))
  };

  return (
    <>
      <SEO
        title="IT Services & Solutions | Tim's Tech Massachusetts"
        description="Comprehensive IT services including cybersecurity, smart automation, and network solutions. 24/7 expert support for Massachusetts businesses."
        pathname="/services"
      >
        <script type="application/ld+json">
          {JSON.stringify(servicesSchema)}
        </script>
      </SEO>

      <PageLayout
        title="Our Services"
        description="Professional IT solutions tailored for businesses and individuals across New England"
      >
        {/* Primary Services */}
        <ServiceSection 
          services={services.primary}
          variant="primary"
          className="py-16"
        />

        {/* Secondary Services */}
        <ServiceSection 
          services={services.secondary}
          variant="secondary"
          columns={4}
          className="py-16"
        />

        <CTASection
          title="Ready to transform your business?"
          description="We're available 24/7 to help with all your technology needs"
          primaryButtonText="Contact Us"
          primaryButtonLink="/contact"
          secondaryButtonText="Service Areas"
          secondaryButtonLink="/areas"
        />
      </PageLayout>
    </>
  );
};

export default ServicesPage;