import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';


const CTASection = ({ 
  title = "Ready to transform your business?",
  description = "We're available 24/7 to help with all your technology needs",
  primaryButtonText = "Get Started",
  primaryButtonLink = "/contact",
  secondaryButtonText,
  secondaryButtonLink
}) => {
  const { theme, setTheme } = useTheme();
  return (
    <section className="cta-section">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="cta-container"
      >
        <h2 className="cta-title">
          {title}
        </h2>
        <p className="cta-description">
          {description}
        </p>
        <div className="cta-buttons">
          <motion.a
            href={primaryButtonLink}
            className="cta-primary-button"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            {primaryButtonText}
          </motion.a>
          {secondaryButtonText && (
            <motion.a
              href={secondaryButtonLink}
              className="cta-secondary-button"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              {secondaryButtonText}
            </motion.a>
          )}
        </div>
      </motion.div>
    </section>
  );
};

export default CTASection;