import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../../contexts/ThemeContext';

const SearchAndFilter = ({ 
  searchTerm, 
  setSearchTerm, 
  selectedLetter, 
  setSelectedLetter,
  viewMode,
  setViewMode 
}) => {
  const { theme, setTheme } = useTheme();
  const alphabet = ['All', ...('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''))];

  return (
    <div className="max-w-4xl mx-auto flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search areas..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
          {searchTerm && (
            <button
              onClick={() => setSearchTerm('')}
              className="search-clear-button"
            >
              ✕
            </button>
          )}
        </div>
        <div className="view-controls">
          <button
            onClick={() => setViewMode('list')}
            className={`view-mode-button ${viewMode === 'list' ? 'active' : 'inactive'}`}
          >
            List
          </button>
          <button
            onClick={() => setViewMode('map')}
            className={`view-mode-button ${viewMode === 'map' ? 'active' : 'inactive'}`}
          >
            Map
          </button>
        </div>
      </div>

      <div className="filters-container">
        {alphabet.map(letter => (
          <motion.button
            key={letter}
            onClick={() => setSelectedLetter(selectedLetter === letter ? '' : letter)}
            className={`filter-button ${selectedLetter === letter ? 'active' : 'inactive'}`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 15
            }}
          >
            {letter}
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default SearchAndFilter;