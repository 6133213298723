import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from '../../contexts/ThemeContext';


const SEO = ({ 
  title = "Tim's Tech - Professional IT Services in Massachusetts",
  description = "24/7 professional IT services, including cybersecurity, business automation, and computer repair. Serving Massachusetts businesses since 2012.",
  pathname = "",
  children
}) => {
  const siteUrl = "https://tims-tech.com";
  const fullUrl = `${siteUrl}${pathname}`;
  const { theme, setTheme } = useTheme();
  
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Tim's Tech",
    "image": "https://tims-tech.com/images/logos/logo.png",
    "description": "Professional IT services including cybersecurity, business automation, and computer repair.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "130 Western Ave",
      "addressLocality": "Lowell",
      "addressRegion": "MA",
      "postalCode": "01851",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 42.63964,
      "longitude": -71.32020
    },
    "url": siteUrl,
    "telephone": "+17813668800",
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    }
  };

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={fullUrl} />
      
      {/* Open Graph */}
      <meta property="og:url" content={fullUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Tim's Tech" />
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      
      {/* Base Schema */}
      <script type="application/ld+json">
        {JSON.stringify(baseSchema)}
      </script>

      {/* Additional tags */}
      {children}
    </Helmet>
  );
};

export default SEO;