import React, { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import SEO from './shared/SEO';
import PageLayout from './layout/PageLayout';
import { getAllAreas } from '../data/serviceAreas';
import AreasGrid from './areas/AreasGrid';
import Map from './areas/Map';
import SearchAndFilter from './areas/SearchAndFilter';
import { useTheme } from '../contexts/ThemeContext';

const AreasPage = () => {
  const { theme, setTheme } = useTheme();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLetter, setSelectedLetter] = useState('');
  const [viewMode, setViewMode] = useState('list');
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Fetch user's approximate location based on IP address
    axios.get('https://ipapi.co/json/')
      .then(response => {
        const { latitude, longitude } = response.data;
        setUserLocation([latitude, longitude]);
      })
      .catch(error => {
        console.error('Error fetching user location:', error);
      });
  }, []);

  const allAreas = getAllAreas();

  const filteredAreas = useMemo(() => {
    return allAreas
      .filter(area => {
        const matchesSearch = area.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesLetter = selectedLetter === 'All' || !selectedLetter || area.name.startsWith(selectedLetter);
        return matchesSearch && matchesLetter;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [allAreas, searchTerm, selectedLetter]);

  const areasSchema = {
    "@context": "https://schema.org",
    "@type": "ServiceAreas",
    "provider": {
      "@type": "LocalBusiness",
      "name": "Tim's Tech"
    },
    "areaServed": allAreas.map(area => ({
      "@type": "City",
      "name": area.name,
      "containedIn": {
        "@type": "State",
        "name": area.state
      }
    }))
  };

  return (
    <>
      <SEO
        title="Service Areas | Tim's Tech IT Support Coverage"
        description="Professional IT services available across Massachusetts. Serving Boston, Cambridge, Lowell, and surrounding areas with 24/7 tech support."
        pathname="/areas"
      >
        <script type="application/ld+json">
          {JSON.stringify(areasSchema)}
        </script>
      </SEO>

      <PageLayout
        title="Areas We Serve"
        description="Professional IT services available across New England"
      >
        {/* Service Area Notice */}
        <section className="py-4">
          <div className="container mx-auto px-4">
            <div className="max-w-4xl mx-auto text-center">
              <p className="text-text-secondary text-sm italic">
                Don't see your area listed? We may still be able to assist - 
                our technicians serve communities throughout New England.
              </p>
            </div>
          </div>
        </section>

        {/* Search and View Toggle */}
        <section className="py-8 bg-areas-bg">
          <div className="container mx-auto px-4">
            <SearchAndFilter
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              selectedLetter={selectedLetter}
              setSelectedLetter={setSelectedLetter}
              viewMode={viewMode}
              setViewMode={setViewMode}
            />
          </div>
        </section>

        {/* Areas Display */}
        {viewMode === 'list' && (
          <section className="areas-section">
            <div className="container mx-auto px-4">
              <motion.div
                key={`${selectedLetter}-${searchTerm}`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, ease: "easeOut" }}
                className="min-h-[200px]"
              >
                {filteredAreas.length > 0 ? (
                  <AreasGrid areas={filteredAreas} />
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    className="text-center py-8 text-gray-500"
                  >
                    No areas found matching your search.
                  </motion.div>
                )}
              </motion.div>
            </div>
          </section>
        )}

        {/* Map Display */}
        {viewMode === 'map' && (
          <section className="py-16 bg-bg-tertiary">
            <div className="container mx-auto px-4">
              <motion.div
                key="map"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="content-max-width bg-bg-secondary rounded-xl overflow-hidden map-container"
              >
                <Map userLocation={userLocation} areas={filteredAreas} />
              </motion.div>
            </div>
          </section>
        )}
      </PageLayout>
    </>
  );
};

export default AreasPage;